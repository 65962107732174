"use client";

import { useEffect } from "react";

import { QuestionOne } from "@/config/app-constant";
import useGtmPopup from "@/hooks/gtm/useGtmPopup";
import { useAppDispatch } from "@/store/hook";
import { setApiError } from "@/store/slices/errors/api-error.slice";
import { storageAvailable } from "@/utils/common.utils";

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  useGtmPopup({
    tripType: QuestionOne,
    isPopupOpen: !storageAvailable(),
    popupName: "Cookies are blocked",
  });

  if (!storageAvailable()) {
    dispatch(
      setApiError({
        errorStatus: true,
        modalTitle: "Cookies are blocked",
        modalDescription:
          "Unfortunately our journey cannot function with all cookies blocked from Safari settings. To get a quote, please unblock all cookies and manage your cookies via OneTrust on our website.",
        ctaPrimaryBtnLabel: "Refresh",
        httpCode: null,
        errorCode: "456",
        errorMessage: "",
        errors: null,
        multiButton: false,
      })
    );
  }

  return (
    <div>
      <h2>Something went wrong!</h2>
    </div>
  );
}
